<ng-container #content>
  <div class="modal-body" id="privacy-modal">
    <div class="row col-xl-12 col-lg-12 col-md-12 col-sm-12">
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
        <a href="/" (click)="close()">
          <img class="logo-ipanema" src="../assets/images/logo.svg" alt="Ipanema" rel="preload" />
        </a>
      </div>
      <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 d-flex flex-row-reverse">
        <div class="close-button">
          <a class="close-text" (click)="close($event)"><span class="close-icon">{{
              'BUTTONS.CLOSE-BUTTON' | translate
              }}</span>
            {{ 'BUTTONS.CLOSE' | translate }}</a>
        </div>
      </div>
    </div>
    <div class="privacy-content container">
      <div class="row col-xl-9 col-lg-9 col-md-10 col-sm-10">
        <h1>{{ 'PRIVACY.TITLE' | translate }}</h1>
      </div>

      <div class="row col-xl-8 col-lg-8 col-md-8 col-sm-8 subtitle">
        <h6>{{ 'PRIVACY.SUBTITLE' | translate }}</h6>
      </div>

      <!-- <div class="card">
        <div class="row col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <div class="col-xl-8 col-lg-8 col-md-10 col-sm-10 card-title">
            <h3>{{ 'PRIVACY.COOKIE.PERFORMANCE.TITLE' | translate }}</h3>
          </div>

          <div class="
              col-xl-4 col-lg-4 col-md-2 col-sm-2
              card-title
              d-flex
              flex-row-reverse
            ">
            <div class="row">
              <div class="slide-text" [hidden]="!isPerformance">
                <h6>{{ 'PRIVACY.COLLECT.TRUE' | translate }}</h6>
              </div>
              <div class="slide-text" [hidden]="isPerformance">
                <h6>{{ 'PRIVACY.COLLECT.FALSE' | translate }}</h6>
              </div>
              <div>
               <label class="switch">
                <label>
                  Não coletado
                  <input type="checkbox" (change)="checkedToggle($event, 'Performance')" [checked]="isPerformance" />
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
          </div>

          <div class="col-xl-8 col-lg-8 col-md-10 col-sm-10 card-subtitle">
            <h6>{{ 'PRIVACY.COOKIE.PERFORMANCE.SUBTITLE' | translate }}</h6>
          </div>
        </div>
      </div> -->

      <div class="card">
        <div class="row col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <div class="col-xl-8 col-lg-8 col-md-10 col-sm-10 card-title">
            <h3>{{ 'PRIVACY.COOKIE.ANALYTICS.TITLE' | translate }}</h3>
          </div>

          <div class="
              col-xl-4 col-lg-4 col-md-2 col-sm-2
              card-title
              d-flex
              flex-row-reverse
            ">
            <div class="row">
              <div class="slide-text" [hidden]="!isAnalytics">
                <h6>{{ 'PRIVACY.COLLECT.TRUE' | translate }}</h6>
              </div>
              <div class="slide-text" [hidden]="isAnalytics">
                <h6>{{ 'PRIVACY.COLLECT.FALSE' | translate }}</h6>
              </div>
              <div>
                <label class="switch">
                  <input type="checkbox" (change)="checkedToggle($event, 'Analytics')" [checked]="isAnalytics" />
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
          </div>

          <div class="col-xl-8 col-lg-8 col-md-10 col-sm-10 card-subtitle">
            <h6>{{ 'PRIVACY.COOKIE.ANALYTICS.SUBTITLE' | translate }}</h6>
          </div>
        </div>
      </div>

      <!-- <div class="card">
        <div class="row col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <div class="col-xl-8 col-lg-8 col-md-10 col-sm-10 card-title">
            <h3>{{ 'PRIVACY.COOKIE.ADVERTISING.TITLE' | translate }}</h3>
          </div>

          <div class="
              col-xl-4 col-lg-4 col-md-2 col-sm-2
              card-title
              d-flex
              flex-row-reverse
            ">
            <div class="row">
              <div class="slide-text" [hidden]="!isAdvertising">
                <h6>{{ 'PRIVACY.COLLECT.TRUE' | translate }}</h6>
              </div>
              <!-- <div class="slide-text" [hidden]="isAdvertising">
                <h6>{{ 'PRIVACY.COLLECT.FALSE' | translate }}</h6>
              </div>
              <div>
                <!-- <label class="switch"> -
                <label>
                  Não coletado
                  <!-- <input type="checkbox" (change)="checkedToggle($event, 'Advertising')" [checked]="isAdvertising" />
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
          </div>

          <div class="col-xl-8 col-lg-8 col-md-10 col-sm-10 card-subtitle">
            <h6>{{ 'PRIVACY.COOKIE.ADVERTISING.SUBTITLE' | translate }}</h6>
          </div>
        </div>
      </div> -->

      <!-- <div class="card">
        <div class="row col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <div class="col-xl-8 col-lg-8 col-md-10 col-sm-10 card-title">
            <h3>{{ 'PRIVACY.COOKIE.ESSENTIAL.TITLE' | translate }}</h3>
          </div>

          <div class="
              col-xl-4 col-lg-4 col-md-2 col-sm-2
              card-title
              d-flex
              flex-row-reverse
            ">
            <div class="row">
              <div class="slide-text" [hidden]="!isEssential">
                <h6>{{ 'PRIVACY.COLLECT.TRUE' | translate }}</h6>
              </div>
              <!-- <div class="slide-text" [hidden]="isEssential">
                <h6>{{ 'PRIVACY.COLLECT.FALSE' | translate }}</h6>
              </div>
              <div>
                <!-- <label class="switch">
                <label>
                  Não coletado
                  <!-- <input type="checkbox" (change)="checkedToggle($event, 'Essential')" [checked]="isEssential" />
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
          </div>

          <div class="col-xl-8 col-lg-8 col-md-10 col-sm-10 card-subtitle">
            <h6>{{ 'PRIVACY.COOKIE.ESSENTIAL.SUBTITLE' | translate }}</h6>
          </div>
        </div>
      </div> -->

      <!-- <div class="card">
        <div class="row col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <div class="col-xl-8 col-lg-8 col-md-10 col-sm-10 card-title">
            <h3>{{ 'PRIVACY.COOKIE.THIRD.TITLE' | translate }}</h3>
          </div>

          <div class="
              col-xl-4 col-lg-4 col-md-2 col-sm-2
              card-title
              d-flex
              flex-row-reverse
            ">
            <div class="row">
              <div class="slide-text" [hidden]="!isThird">
                <h6>{{ 'PRIVACY.COLLECT.TRUE' | translate }}</h6>
              </div>
              <!-- <div class="slide-text" [hidden]="isThird">
                <h6>{{ 'PRIVACY.COLLECT.FALSE' | translate }}</h6>
              </div>
      <div>
        <!-- <label class="switch">
        <label>
          Não coletado
          <!-- <input type="checkbox" (change)="checkedToggle($event, 'Third')" [checked]="isThird" />
                  <span class="slider round"></span>
        </label>
      </div>
    </div>
  </div>

  <div class="col-xl-8 col-lg-8 col-md-10 col-sm-10 card-subtitle">
    <h6>{{ 'PRIVACY.COOKIE.THIRD.SUBTITLE' | translate }}</h6>
  </div>
  </div>
  </div> -->

      <div class="row">
        <div class="col-12">
          <strong><a class="personalize" href="../../assets/files/termos-de-uso-ipanema.pdf" target="_blank">{{
            'BANNER.BANNER-POLICY' | translate
            }} <img src="../../../../assets/images/export-blue.svg"> &nbsp;&nbsp;</a>
           <a class="personalize" href="../../assets/files/politica-de-cookies-ipanema.pdf" target="_blank">{{
            'BANNER.COOKIE-POLICY' | translate
            }} <img src="../../../../assets/images/export-blue.svg"> </a>
        </strong>
        </div>
      </div>

      <div class="row lgpd-buttons">
        <div class="">
          <button type="button" class="light mr-2" (click)="reject()">
            {{ 'BANNER.BUTTON.UNACCEPT' | translate }}
          </button>
          <button type="button" class="primary mr-2" (click)="save()">
            {{ 'BANNER.BUTTON.SAVE' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>