import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { EventEmitterService } from '../../../utils/service/event-emitter.service';
import { MenuModel } from './config/menu.model';
import { MenuConfig } from './config/menu.config';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../../../services/authentication.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  selector: 'app-public-menu',
})
export class MenuComponent implements OnInit {
  @ViewChild('toggleButton') toggleButton: ElementRef;
  @ViewChild('menu') menu: ElementRef;
  items: MenuModel[] = MenuConfig.items;
  negotiateConfig = { identifier: '', isPrimary: false };
  menuConfig = { identifier: 'home', isPrimary: false };
  signup = this.items.filter((i) => i.identifier == 'signup')[0];
  toggled = false;
  setClassHeader = false;
  buttonsItems: MenuModel[];
  menuBtnClick: boolean;
  openMenu: boolean;

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private authenticationService: AuthenticationService,
    private gtmService: GoogleTagManagerService,
    private renderer: Renderer2,
    private el: ElementRef
  ) {
    router.events.subscribe((val) => { });
    EventEmitterService.get('init').subscribe((res) => {
      const returnIpanema = JSON.parse(sessionStorage.getItem('returnIpanema'));

      if (returnIpanema) {
        this.buttonsItems = [
          {
            identifier: 'dashboard',
            route: 'painel',
            text: 'MENU.PANEL',
            isButton: true,
          },
        ];
      } else {
        this.buttonsItems = [
          {
            identifier: 'signup',
            route: 'cadastrar',
            text: 'MENU.SIGNUP',
            isButton: true,
            fibbia_evt_collector: 'true',
            fibbia_evt_category: 'f3682a46-8e88-486a-ae4a-1b3ef41f4d38',
            fibbia_evt_type: '18bde62f-bc03-4c32-96fe-bf58df7b81c4',
            fibbia_evt_context_id: 'cadastrar',
            fibbia_evt_element_id: 'Cadastrar',
          },
          {
            identifier: 'signin',
            route: 'entrar',
            text: 'MENU.SIGNIN',
            isButton: true,
            buttonStyle: 'btn btn-primary',
            fibbia_evt_collector: 'true',
            fibbia_evt_category: 'f3682a46-8e88-486a-ae4a-1b3ef41f4d38',
            fibbia_evt_type: '18bde62f-bc03-4c32-96fe-bf58df7b81c4',
            fibbia_evt_context_id: 'entrar',
            fibbia_evt_element_id: 'Entrar',
          },
        ];
      }

      this.menuConfig = res;
    });
  }

  async ngOnInit() {
    this.openMenu = false;
    this.setClassHeader = !(await this.route.snapshot.data.active);
  }

  click(toggled) {
    this.toggled = toggled;
  }

  blurClick() {
    this.renderer.listen('window', 'click', (e: Event) => {
      const target = e.target as Element;
      if (target.classList.value != 'dropdown-toggle nav-link') {
        this.toggled = false;
      }
    });
  }

  blurMenu(event) {
    event.preventDefault();
    event.stopPropagation();
    this.openMenu = false;
  }

  openWhatsappCommon(event) {
    event.preventDefault();
    event.stopPropagation();

    window.open('https://api.whatsapp.com/send?phone=5508000250000', '_blank')
  }
  openWhatsappCourtDebts(event) {
    event.preventDefault();
    event.stopPropagation();

    window.open('https://api.whatsapp.com/send?phone=5511982738944', '_blank')
  }
  openCallCommon(event) {
    event.preventDefault();
    event.stopPropagation();

    window.location.href = 'tel:08000250000'
  }
  openCallCourtDebts(event) {
    event.preventDefault();
    event.stopPropagation();

    window.location.href = 'tel:08000180048'
  }

  goHome() {
    this.router.navigate([`/`]);
  }

  public itemsButtonGTM(items) {
    switch (items.identifier) {
      case 'about':
        const gtmTag1 = {
          event: 'eventGA',
          category: 'portal:menu-topo',
          action: 'clicou',
          label: 'link:sobre',
        };
        this.gtmService.pushTag(gtmTag1);
        break;

      case 'contact':
        const gtmTag2 = {
          event: 'eventGA',
          category: 'portal:menu-topo',
          action: 'clicou',
          label: 'link:contato',
        };
        this.gtmService.pushTag(gtmTag2);
        break;

      case 'signup':
        const gtmTag3 = {
          event: 'eventGA',
          category: 'portal:menu-topo',
          action: 'clicou',
          label: 'botao:cadastrar',
        };
        this.gtmService.pushTag(gtmTag3);
        break;

      case 'signup':
        const gtmTag4 = {
          event: 'eventGA',
          category: 'portal:menu-topo',
          action: 'clicou',
          label: 'botao:entrar',
        };
        this.gtmService.pushTag(gtmTag4);
        break;

      case 'dropdown':
        const gtmTag5 = {
          event: 'eventGA',
          category: 'portal:menu-topo',
          action: 'clicou',
          label: 'link:dropdown',
        };
        this.gtmService.pushTag(gtmTag5);
        break;

      default:
        break;
    }
  }

  public registerLoginButtonGTM(item) {
    switch (item.identifier) {
      case 'signup':
        const gtmTag1 = {
          event: 'eventGA',
          category: 'portal:menu-topo',
          action: 'clicou',
          label: 'botao:cadastrar',
        };
        this.gtmService.pushTag(gtmTag1);
        break;

      case 'signin':
        const gtmTag2 = {
          event: 'eventGA',
          category: 'portal:menu-topo',
          action: 'clicou',
          label: 'botao:entrar',
        };
        this.gtmService.pushTag(gtmTag2);
        break;

      case 'dashboard':
        const gtmTag3 = {
          event: 'eventGA',
          category: 'portal:meu-espaco',
          action: 'clicou',
          label: 'botao:meu-espaco',
        };
        this.gtmService.pushTag(gtmTag3);
        break;

      default:
        break;
    }
  }

  public whatsappButtonGTM() {
    const gtmTag = {
      event: 'eventGA',
      category: 'portal:menu-contatos',
      action: 'clicou',
      label: 'botao:whatsapp',
    };
    this.gtmService.pushTag(gtmTag);
  }

  public messengerButtonGTM() {
    const gtmTag = {
      event: 'eventGA',
      category: 'portal:cadastro',
      action: 'clicou',
      label: 'botao:chat',
    };
    this.gtmService.pushTag(gtmTag);
  }
}
