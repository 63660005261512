import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'src/app/services/cookie.service';
import { CookieConstant } from '../../cookie.constant';

@Component({
  selector: 'app-privacy-center',
  templateUrl: './privacy-center.component.html',
  styleUrls: ['./privacy-center.component.scss']
})
export class PrivacyCenterComponent implements OnInit {

  isPerformance: boolean = false;
  isAnalytics: boolean = false;
  isAdvertising: boolean = false;
  isEssential: boolean = false;
  isThird: boolean = false;

  constructor(
    private activeModal: NgbActiveModal,
    private cookieService: CookieService
  ) { }

  ngOnInit(): void {
    let cookie = this.cookieService.getCookie();

    if (cookie != null && cookie.AcceptAll) {
      this.isPerformance = true;
      this.isAnalytics = true;
      this.isAdvertising = true;
      this.isEssential = true;
      this.isThird = true;
    } else if (cookie != null && cookie.Custom) {
      this.isPerformance = cookie.Custom.Performance;
      this.isAnalytics = cookie.Custom.Analytics;
      this.isAdvertising = cookie.Custom.Advertising;
      this.isEssential = cookie.Custom.Essential;
      this.isThird = cookie.Custom.Thirdparties;
    } else {
      //default
      this.isPerformance = false;
      this.isAnalytics = false;
      this.isAdvertising = false;
      this.isEssential = false;
      this.isThird = false;
    }
  }

  reject(): void {
    this.cookieService.setRejectAll();
    this.close();
  }

  save(): void {
    this.cookieService.setCustomCookie(this.isPerformance, this.isAnalytics, this.isAdvertising, this.isEssential, this.isThird);
    this.close();
  }

  close(event?) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.activeModal.close();
  }

  checkedToggle(e, element) {
    switch (element) {
      case 'Performance':
        this.isPerformance = e.target.checked;
        break;
      case 'Analytics':
        this.isAnalytics = e.target.checked;
        break;
      case 'Advertising':
        this.isAdvertising = e.target.checked;
        break;
      case 'Essential':
        this.isEssential = e.target.checked;
        break;
      case 'Third':
        this.isThird = e.target.checked;
        break;
    }
  }

}
